import React from 'react'

export default function FaqSection({faqData}) {
    return <>
        <a id="faq" href='#faq' style={{visibility: 'hidden'}}>_</a>
        <section className="pricing_area sec_pad">
            <div className="container custom_container p0">
                <div className="sec_title text-center">
                    <h2 className="f_p f_size_30 l_height50 f_700 t_color2">{faqData.title}</h2>
                    <p className="f_300 f_size_16 l_height30 mb-0">{faqData.description}</p>
                </div>
                <div className="tab-content faq_content" id="myFaqTabContent">
                    <div className="tab-pane fade show active" id="purchas" role="tabpanel"
                         aria-labelledby="purchas-tab">
                        <div className="row">
                            {faqData.faqGroups.map((group, idx) => <div key={idx} className="col-lg-6 col-sm-6 mt-4">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">{group.title}</h3>
                                <div id="accordion_{{group.sys.id}}">
                                    {group.faqItems.map((item, jdx) => <div key={jdx} className="card">
                                        <div className="card-header" id={`heading_${item.sys.id}`}>
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse"
                                                        data-target={`#collapse_${item.sys.id}`} aria-expanded="false"
                                                        aria-controls={`collapse_${item.sys.id}`}>
                                                    {item.title}<i className="ti-plus"></i><i
                                                    className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id={`collapse_${item.sys.id}`} className="collapse"
                                             aria-labelledby={item.sys.id}
                                             data-parent={`#accordion_${group.sys.id}`}>
                                            <div className="card-body">
                                                {item.description}
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>

            </div>
        </section>
        </>;
}
