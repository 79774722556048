import React from 'react'

export default function TextSection({pageData, textData}) {
    const ltrStyle = ['2lHtm3mdQOfmzkkpiqTgba', '6Cnc98o1db5Jl9IjIIa6x3', '3tPH6WqbXV8QxmS7baCx8C']
        .find(id => id === pageData.contentful_id) ? {direction: 'ltr', textAlign: 'left'} : undefined;

    return <section className="job_details_area sec_pad">
        <div className="container">
            <div className="row flex-row-reverse">
                <div className='col-lg-12 pl_70' style={ltrStyle}>
                    {textData.title || textData.subTitle ? <>
                            {textData.title ? <h2 className="f_p f_size_30 l_height50 f_600 t_color">{textData.title}</h2> : null}
                            {textData.subTitle ? <div className="f_300 f_size_16" dangerouslySetInnerHTML={{__html: textData.childContentfulTextSectionSubTitleTextNode.childMarkdownRemark.html}}/> : null}
                    </> : null}
                    <div dangerouslySetInnerHTML={{__html: textData.childContentfulTextSectionContentTextNode?.childMarkdownRemark.html}} />
                    <div className="hr mt_100 mt-30 mb-0"></div>
                </div>
            </div>
        </div>
    </section>
        ;
}
