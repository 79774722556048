import React from 'react'
import ContactUsForm from '../forms/contact-us-form';

export default function ContactUsSection() {
    return <section className="contact_info_area sec_pad bg_color">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 pr-0">
                    <div className="contact_info_item">
                        <h6 className="f_p f_size_20 t_color3 f_500 mb_20">כתובתנו</h6>
                        <p className="f_300 f_size_15">יגאל אלון 114, תל-אביב</p>
                    </div>
                    <div className="contact_info_item">
                        <h6 className="f_p f_size_20 t_color3 f_500 mb_20">פרטי התקשרות</h6>
                        <p className="f_300 f_size_15"><span className="f_400 t_color3">טלפון:</span> <a
                            style={{direction: 'ltr'}} href="tel:+972-3-5733133">+972-3-5733133</a></p>
                        <p className="f_300 f_size_15"><span className="f_400 t_color3">כתובת מייל:</span> <a
                            style={{direction: 'ltr'}} href="mailto:team@propertime.co.il">team@propertime.co.il</a></p>
                    </div>
                </div>
                <div className="col-lg-8 offset-lg-1">
                    <div className="mapbox">
                        <div id="mapBox" className="row m0"
                             data-lat="32.0726127"
                             data-lon="34.7928231"
                             data-zoom="15"
                             data-info="פרומתאוס - יגאל אלון 114, תל-אביב"
                             data-marker="/assets/img/pmts-location-marker.png"
                             data-mlat="32.0726127"
                             data-mlon="34.7928231">
                        </div>
                    </div>
                </div>
            </div>
            <ContactUsForm />
        </div>
    </section>
        ;
}
