import React from 'react'

export default function MediumHeader({mediumHeaderData}) {
    return <section className="breadcrumb_area">
        <img className="breadcrumb_shap" src="/assets/img/breadcrumb/banner_bg.png" alt=""/>
        <div className="container">
            <div className="breadcrumb_content text-center">
                <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">{mediumHeaderData.title}</h1>
            </div>
        </div>
    </section>;
}
