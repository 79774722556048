import React from 'react'
import Helmet from 'react-helmet';

export default function Navigation({navbarData, pageData, homeData, isHome}) {
    return <nav className="navbar navbar-expand-lg menu_one menu_eight">
        <div className="container">
            <a className="navbar-brand sticky_logo" href="/"><img src={navbarData.logo.image.file.url}
                                                                  srcSet={`${navbarData.logo2x.image.file.url} 2x`}
                                                                  alt="logo"/><img
                src={navbarData.stickyLogo.image.file.url}
                srcSet={`${navbarData.stickyLogo2x.image.file.url} 2x`} alt=""/></a>
            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
            <span className="menu_toggle">
                <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                </span>
            </span>
            </button>

            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                <ul className="navbar-nav menu w_menu ml-auto">
                    {navbarData.menu.includeHomeSections ? homeData.contentModules.map((section, idx) => section.linkText && <li key={idx} className="nav-item">
                        <a href={`${isHome ? '' : '/'}#${section.anchor}`}
                           className="nav-link">{section.linkText}</a>
                    </li>) : null}
                    {navbarData.menu.includePageSections ? pageData.contentModules.map((section, idx) => section.linkText && <li key={idx} className="nav-item">
                        <a href={`#${section.anchor}`}
                           className="nav-link">{section.linkText}</a>
                    </li>) : null}
                    {navbarData.menu.items?.map((item, idx) => <li key={idx} className="nav-item">
                        <a href={item.url}
                           className={`nav-link ${item.cssClasses?.join(' ')}`}>{item.text}</a>
                    </li>)}
                </ul>
                <a className="typeform-share btn_get btn_hover saas_btn hidden-sm hidden-xs"
                   href="https://propertime.typeform.com/to/ELjnm3" data-mode="popup" data-submit-close-delay="5"
                   target="_blank" rel="noreferrer">{navbarData.callToAction.text} </a>
                <Helmet>
                <script dangerouslySetInnerHTML={{
                    __html: `
                (function() {var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id='typef_orm_share', b='https://embed.typeform.com/'; if(!gi.call(d,id)){js=ce.call(d,'script'); js.id=id; js.src=b+'embed.js'; q=gt.call(d,'script')[0]; q.parentNode.insertBefore(js,q)}})()
                `
                }}/>
                </Helmet>
            </div>
        </div>
    </nav>
        ;
}
