import React from 'react'

export default function FeaturesSection({featuresData}) {
    return <>
        <a id="features" href='#features' style={{visibility: 'hidden'}}>_</a>
        <section className="saas_features_area_two">
            <div className="container">
                <div className="sec_title text-center mb_70 wow fadeInUp" data-wow-delay="0.3s">
                    <h2 className="f_p f_size_30 l_height50 f_600 t_color">{featuresData.title}</h2>
                    <div className="f_300 f_size_16" dangerouslySetInnerHTML={{__html: featuresData.childContentfulFeaturesSectionDescriptionTextNode.childMarkdownRemark.html}}/>
                </div>
                <div className="row mb_30">
                    {featuresData.features.map((feature, idx) => <div key={idx} className="col-lg-4 col-sm-6">
                        <div className="saas_features_item text-center wow fadeInUp"
                             data-wow-delay="{% cycle '0.3s', '0.5s', '0.7s' %}">
                            <img src={feature.image.image.file.url} alt={feature.image.name}/>
                            <h4 className="f_size_20 f_p t_color f_500">{feature.title}</h4>
                            <div className="f_300 f_size_15 mb-0" dangerouslySetInnerHTML={{__html: feature.childContentfulFeatureDescriptionTextNode.childMarkdownRemark.html}} />
                        </div>
                    </div>)}
                </div>
                <div className="hr mt_100 mb-0"></div>
            </div>
        </section>
    </>
        ;
}
