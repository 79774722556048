import React from 'react'
import {graphql, useStaticQuery} from 'gatsby';

 const BlogMansorySection = () => {
     const data = useStaticQuery(graphql`
        query {
          allContentfulPost(filter: {isKnowledgeBaseArticle: {eq: true}}, sort: {order: DESC, fields: publishedOn}) {
            edges {
              node {
                isKnowledgeBaseArticle
                contentful_id
                slug
                featuredImage {
                  file {
                    url
                  }
                  title
                }
                categories
                childContentfulPostPostContentTextNode {
                  childMarkdownRemark {
                    excerpt(pruneLength: 90, truncate: true, format: PLAIN)
                  }
                }
                title
                publishedOn(formatString: "LL")
              }
            }
            nodes {
              id
            }
          }
        }
      `)

        const posts = data.allContentfulPost.edges.map(e => e.node);

        return <section className="blog_area sec_pad">
            <div className="container">
                <div className="row" id="blog_masonry">
                    {posts.map((post, idx) => <div key={idx} className="col-lg-4 col-sm-6">
                        <div className="blog_grid_item mb-30">
                            <div className="blog_img">
                                <a href={`/kb/${post.slug}/`}><img src={post.featuredImage.file.url}
                                                                   alt={post.featuredImage.title}/></a>
                            </div>
                            <div className="blog_content">
                                <div className="entry_post_info">
                                    {post.publishedOn}
                                </div>
                                <a href={`/kb/${post.slug}/`}><h5 className="f_p f_size_20 f_500 t_color mb_20">
                                    {post.title}</h5></a>
                                <p className="f_300 mb-0">{post.postContent}</p>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </section>
            ;
}
export default BlogMansorySection;
