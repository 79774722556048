import React from 'react'
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';

export default function SEOHeader(props) {
    const {location, seo, pageData} = props;
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterUsername
            siteUrl
          }
        }
      }
    `
    )

    return <Helmet>
        <link rel="canonical" href={`${site.siteMetadata.siteUrl}${location?.pathname}`}/>
        <meta name="description" content={seo?.metaDescription?.metaDescription || site.siteMetadata.description}/>
        <meta property="og:locale" content="he_IL"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={pageData.title || site.siteMetadata.title}/>
        <meta property="og:description" content={seo?.metaDescription?.metaDescription || site.siteMetadata.description}/>
        <meta property="og:url" content={`${site.siteMetadata.siteUrl}${location?.pathname}`}/>
        {seo?.metaImage.url && <meta property="og:image" content={seo?.metaImage.url}/>}
        <meta property="og:site_name" content={site.siteMetadata.title}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={pageData.title || site.siteMetadata.title}/>
        <meta name="twitter:site" content={`@${site.siteMetadata.twitterUsername}`}/>
        <meta name="twitter:creator" content={`@${site.siteMetadata.twitterUsername}`}/>
        {seo?.metaImage.file.url && <meta property="twitter:image" content={seo?.metaImage.file.url}/>}
    </Helmet>;
}
