import React from 'react'

export default function HighlightsSection({highlightsData}) {
    return <>
        <a id="highlights" href='#highlights' style={{visibility: 'hidden'}}>_</a>
        <section className="saas_service_area sec_pad">
            <div className="container">
                <div className="sec_title text-center mb_70 wow fadeInUp" data-wow-delay="0.3s">
                    <h2 className="f_p f_size_30 l_height50 f_600 t_color">{highlightsData.title}</h2>
                    <div className="f_300 f_size_16" dangerouslySetInnerHTML={{__html: highlightsData.childContentfulHighlightsSectionDescriptionTextNode?.childMarkdownRemark.html}} />
                </div>
                {highlightsData.highlights.map((highlight, idx) => <div key={idx}
                                                                        className={`row ${idx % 2 !== 0 && 'flex-row-reverse'} saas_service_item`}>
                    <div className="col-lg-6">
                        <div className={`saas_service_img wow ${idx % 2 === 0 ? 'fadeInLeft' : 'fadeInRight'}`}
                             data-wow-delay="0.4s">
                            <img src={highlight.image.image.file.url} alt={highlight.image.name}/>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                        <div
                            className="saas_service_content {% cycle 'pr_100', 'pl_100' %} wow {% cycle 'fadeInRight', 'fadeInLeft' %}"
                            data-wow-delay="{% cycle '0.7s', '0.6s', '0.6s', '0.4s', '0.4s', '0.4s', '0.4s', '0.4s' %}">
                            <div className={`icon ${highlight.iconClass}`} dangerouslySetInnerHTML={{__html: highlight.icon}} />
                            <h4 className="f_500 f_p t_color">{highlight.title}</h4>
                            <div className="f_p f_300" dangerouslySetInnerHTML={{__html: highlight.childContentfulHighlightDescriptionTextNode.childMarkdownRemark.html}}/>
                            {highlight.link && <a href={highlight.link.url} className="gr_btn"><span
                                className="text">{highlight.link.text}</span></a>}
                        </div>
                    </div>
                </div>)}
            </div>
        </section>
    </>
        ;
}
