import React from 'react'

export default function AboutUsSection({aboutUsData}) {
    return <>
        <a id="about-us" href='#about-us' style={{visibility: 'hidden'}}>_</a>
        <section className="sass_partner_logo_area sec_pad">
            <div className="container">
                <div className="sec_title text-center wow fadeInUp" data-wow-delay="0.3s">
                    <h2 className="f_p f_size_30 l_height50 f_600 t_color">{aboutUsData.title}</h2>
                    <div className="f_300 f_size_16" dangerouslySetInnerHTML={{__html: aboutUsData.childContentfulAboutUsSectionSubTitleTextNode.childMarkdownRemark.html}} />
                </div>
                <div className="partner_logo_area_four">
                    <div className="row partner_info">
                        {aboutUsData.customers.map((customer, idx) => <div key={idx} className="logo_item">
                            <img src={customer.image.file.url} alt={customer.name}/>
                        </div>)}
                    </div>
                </div>
            </div>
        </section>
    </>;
}
