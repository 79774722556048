import React from 'react'

export default function TypedMessageSection({typedMessageData}) {
    return <section className="saas_middle_message">
        <h2 className="f_p f_size_32 l_height50 f_600 w_color text-center"><span id="typed"></span><span
            className="typed-cursor"></span></h2>
        <div id="typed-strings" style={{display: 'none'}}>
            {typedMessageData.messages.map((message, idx) => <span key={idx} className="typ">{message}</span>)}
        </div>
    </section>
        ;
}
