import React from "react"
import * as PropTypes from "prop-types"
import PageHead from '../components/page-head';
import PageHeader from '../components/page-header';
import MediumHeader from '../components/medium-header';
import FooterScripts from '../components/footer-scripts';
import BannerSection from '../components/banner-section';
import TextSection from '../components/text-section';
import ContactUsSection from '../components/contact-us-section';
import BlogMansorySection from '../components/blog-mansory-section';
import FeaturesSection from '../components/features-section';
import TypedMessageSection from '../components/typed-message-section';
import HighlightsSection from '../components/highlights-section';
import PricingSection from '../components/pricing-section';
import FaqSection from '../components/faq-section';
import TestimonialSection from '../components/testimonials-section';
import AboutUsSection from '../components/aboutus-section';
import SubscribeSection from '../components/subscribe-section';
import FooterSection from '../components/footer-section';
import BlogContentSection from '../components/blog-content-section';
import {graphql, useStaticQuery} from 'gatsby';

const propTypes = {
    pageData: PropTypes.object.isRequired,
    homeData: PropTypes.object.isRequired,
    isHome: PropTypes.bool.isRequired,
    seo: PropTypes.object,
}

const DefaultLayout = (props) => {
    const {pageData, homeData, isHome, seo} = props;

    const homePageData = useStaticQuery(graphql`
  query {
      contentfulPage(id: {eq: "7b820263-73d9-5083-a447-6c9810977c33"}) {
        id
        contentful_id
        title
        slug
        showMediumHeader
        seo {
          focusKeyPhrase
          metaDescription {
            metaDescription
          }
          metaImage {
            description
            file {
              url
            }
            title
          }
        }
        contentModules {
          ... on ContentfulFooterSection {
            id
            name
            addressLabel
            bottomMenu {
              includeHomeSections
              includePageSections
              items {
                cssClasses
                text
                url
              }
              name
              slug
              title
            }
            copyright
            emailLabel
            facebook
            logo {
              image {
                file {
                  url
                }
                title
              }
              name
              slug
            }
            logo2x {
              image {
                file {
                  url
                }
                title
              }
              name
              slug
            }
            phoneNumber
            phoneNumberLabel
            twitter
            childContentfulFooterSectionAddressTextNode {
              address
              childMarkdownRemark {
                html
              }
            }
            menus {
              includeHomeSections
              includePageSections
              items {
                cssClasses
                text
                url
              }
              name
              slug
              title
            }
          }
          ... on ContentfulSubscribeSection {
            id
            button {
              cssClasses
              text
              url
            }
            contentful_id
            message
          }
          ... on ContentfulTextSection {
            id
            contentful_id
            title
            childContentfulTextSectionContentTextNode {
              content
              childMarkdownRemark {
                html
              }
            }
            childContentfulTextSectionSubTitleTextNode {
              subTitle
              childMarkdownRemark {
                html
              }
            }
            content {
                content
            }
          }
          ... on ContentfulNavBar {
            id
            name
            menu {
              items {
                cssClasses
              }
            }
            callToAction {
              text
            }
            logo {
              image {
                file {
                  url
                }
              }
            }
            logo2x {
              image {
                file {
                  url
                }
              }
            }
            stickyLogo {
              image {
                file {
                  url
                }
              }
            }
            stickyLogo2x {
              image {
                file {
                  url
                }
              }
            }
            menu {
              includeHomeSections
              includePageSections
              items {
                url
                cssClasses
                text
              }
            }
          }
          ... on ContentfulAboutUsSection {
            id
            contentful_id
            linkText
            title
            anchor
            childContentfulAboutUsSectionSubTitleTextNode {
              subTitle
              childMarkdownRemark {
                html
              }
            }
            customers {
              name
              slug
              image {
                file {
                  url
                }
                title
              }
            }
          }
          ... on ContentfulFeaturesSection {
            id
            linkText
            anchor
            childContentfulFeaturesSectionDescriptionTextNode {
                description
                childMarkdownRemark {
                    html
                }
            }
            title
            features {
              image {
                name
                image {
                  file {
                    url
                  }
                }
              }
              childContentfulFeatureDescriptionTextNode {
                description
                childMarkdownRemark {
                    html
                }
              }
              title
            }
          }
          ... on ContentfulTypedMessageSection {
            id
            name
            messages
            contentful_id
          }
          ... on ContentfulHighlightsSection {
            id
            linkText
            anchor
            contentful_id
            title
            childContentfulHighlightsSectionDescriptionTextNode {
              description
              childMarkdownRemark {
                html
              }
            }
            linkText
            highlights {
              childContentfulHighlightDescriptionTextNode {
                description
                childMarkdownRemark {
                  html
                }
              }
              contentful_id
              title
              icon
              iconClass
              image {
                image {
                  file {
                    url
                  }
                  title
                }
                name
              }
            }
          }
          ... on ContentfulTestimonialsSection {
            id
            anchor
            contentful_id
            linkText
            title
            testimonials {
              childContentfulTestimonialQuoteTextNode {
                quote
                childMarkdownRemark {
                  html
                }
              }
              childContentfulTestimonialTitleTextNode {
                title
                childMarkdownRemark {
                  html
                }
              }
              companyLogo {
                file {
                  url
                }
                title
              }
              companyName
              image {
                file {
                  url
                }
              }
              name
            }
          }
          ... on ContentfulBannerSection {
            id
            title
            subTitle
            buttonText
            backgroundImage {
              image {
                file {
                  url
                }
              }
              name
            }
            image {
              name
              image {
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
          }
        }

      }
  }
      `)

    return <>
        <PageHead {...props}/>
        <div className='wrapper'>
            {pageData.sys?.contentType.sys.id === 'post' ? <>
                <PageHeader pageData={pageData} homeData={homePageData.contentfulPage} isHome={false} seo={seo}
                            navbarData={homePageData.contentfulPage.contentModules.find(n => n.__typename === 'ContentfulNavBar')}/>
                <MediumHeader mediumHeaderData={pageData}/>
                <BlogContentSection postData={pageData}/>
                <SubscribeSection subscribeData={homePageData.contentfulPage.contentModules.find(n => n.__typename === 'ContentfulSubscribeSection')}/>
                <FooterSection footerData={homePageData.contentfulPage.contentModules.find(n => n.__typename === 'ContentfulFooterSection')}
                               pageData={pageData} homeData={homePageData.contentfulPage}
                               isHome={false}/>
                </>: null}
            {pageData.contentModules?.map((section, idx) => {
                if (section.__typename === 'ContentfulNavBar') {
                    return <div key={idx}>
                        <PageHeader pageData={pageData} homeData={homeData} isHome={isHome} seo={seo}
                                    navbarData={section}/>
                        {pageData.showMediumHeader && <MediumHeader mediumHeaderData={pageData}/>}
                    </div>;
                } else if (section.__typename === 'ContentfulBannerSection') {
                    return <BannerSection key={idx} bannerData={section}/>;
                } else if (section.__typename === 'ContentfulTextSection') {
                    switch (section.contentful_id) {
                        case '7737hIMKNy7pIRKt2JaTf3':
                            return <ContactUsSection key={idx}/>;
                        case '6ErdVl2iQincsr6rtUIhko':
                            return <BlogMansorySection key={idx}/>;
                        default:
                            return <TextSection key={idx} pageData={pageData} textData={section}/>;
                    }
                } else if (section.__typename === 'ContentfulFeaturesSection') {
                    return <FeaturesSection key={idx} featuresData={section}/>;
                } else if (section.__typename === 'ContentfulTypedMessageSection') {
                    return <TypedMessageSection key={idx} typedMessageData={section}/>;
                } else if (section.__typename === 'ContentfulHighlightsSection') {
                    return <HighlightsSection key={idx} highlightsData={section}/>;
                } else if (section.__typename === 'ContentfulPricingSection') {
                    return <PricingSection key={idx} pricingData={section}/>;
                } else if (section.__typename === 'ContentfulFaqSection') {
                    return <FaqSection key={idx} faqData={section}/>;
                } else if (section.__typename === 'ContentfulTestimonialsSection') {
                    return <TestimonialSection key={idx} testimonialsData={section}/>;
                } else if (section.__typename === 'ContentfulAboutUsSection') {
                    return <AboutUsSection key={idx} aboutUsData={section}/>;
                } else if (section.__typename === 'ContentfulSubscribeSection') {
                    return <SubscribeSection key={idx} subscribeData={section}/>;
                } else if (section.__typename === 'ContentfulFooterSection') {
                    return <FooterSection key={idx} footerData={section} pageData={pageData} homeData={homeData}
                                          isHome={isHome}/>;
                } else {
                    return null;
                }
            })}
        </div>
        <FooterScripts/>
    </>;
}

DefaultLayout.propTypes = propTypes

export default DefaultLayout
