import React from 'react'

export default function PricingSection({pricingData}) {
    return <>
        <a id="pricing" href='#pricing' style={{visibility: 'hidden'}}>_</a>
        <section className="pricing_area sec_pad">
            <div className="container custom_container p0">
                <div className="sec_title text-center">
                    <h2 className="f_p f_size_30 l_height50 f_700 t_color2">{pricingData.title}</h2>
                    <p className="f_300 f_size_16 l_height30 mb-0">{pricingData.subTitle}</p>
                </div>
                <div role="tablist">
                <ul id="myTab" className="nav nav-tabs price_tab mt_70">
                    <li className="nav-item">
                        <a className="nav-link active active_hover" id="home-tab" data-toggle="tab" href="#home"
                           role="tab"
                           aria-controls="home" aria-selected="true">{pricingData.monthlySelector}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                           aria-controls="profile" aria-selected="false">{pricingData.yearlySelector}</a>
                    </li>
                </ul>
                </div>
                <div className="yearly_promotion">
                    <img src="/assets/img/success.png" alt="טיפ מצוין" style={{height: 32, paddingBottom: 2}}/>
                    <span>
            טיפ משתלם במיוחד: חבילות בתשלום שנתי כוללות הנחה בגובה חודשיים!
                </span>
                </div>
                <div className="tab-content price_content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row">
                            {pricingData.plans.filter(p => p.interval === 'monthly').map((plan, idx) =>
                                <div key={idx} className="col-lg-4 col-sm-6">
                                    <div className="price_item">
                                        {plan.badge && <div className="tag_label blue_bg"> {plan.badge}</div>}
                                        <img src={plan.image.image.file.url} alt={plan.image.name}
                                             style={{height: 128}}/>
                                        <h5 className="f_p f_size_20 f_600 t_color2 mt_30">{plan.title}</h5>
                                        <div className="plan-desc">{plan.childContentfulPlanDescriptionTextNode.description}</div>
                                        <div className="price f_700 f_size_40 t_color2">
                                            {plan.price ? <>{plan.currency === 'usd' ? '$' : '₪'}{plan.price}<sub
                                                className="f_size_16 f_300">
                                                {pricingData.monthlyIntervalPresentation}</sub></> : plan.noPriceMessage}
                                        </div>
                                        <ul className="list-unstyled p_list plan-features-list">
                                            {plan.features.map((feature, jdx) => <li key={jdx}
                                                                                     className="{{feature.css_class}}">
                                                {feature.name}</li>)}
                                        </ul>
                                        <a href={ plan.button.url } className="price_btn btn_hover">
                                            {plan.button.text}</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row">
                            {pricingData.plans.filter(p => p.interval === 'yearly').map((plan, idx) =>
                                <div key={idx} className="col-lg-4 col-sm-6">
                                    <div className="price_item">
                                        {plan.badge && <div className="tag_label blue_bg"> {plan.badge}</div>}
                                        <img src={plan.image.image.file.url} alt={plan.image.name}
                                             style={{height: 128}}/>
                                        <h5 className="f_p f_size_20 f_600 t_color2 mt_30">{plan.title}</h5>
                                        <div className="plan-desc">{plan.description}</div>
                                        <div className="price f_700 f_size_40 t_color2">
                                            {plan.price ? <>{plan.currency === 'usd' ? '$' : '₪'}{plan.price}<sub
                                                className="f_size_16 f_300">
                                                {pricingData.yearlyIntervalPresentation}</sub></> : plan.noPriceMessage}
                                        </div>
                                        <ul className="list-unstyled p_list plan-features-list">
                                            {plan.features.map((feature, jdx) => <li key={jdx}
                                                                                     className="{{feature.css_class}}">
                                                {feature.name}</li>)}
                                        </ul>
                                        <a href={ plan.button.url } className="price_btn btn_hover">
                                            {plan.button.text}</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hr mt_100 mb-0"></div>
            </div>
        </section>
    </>
        ;
}
