import React from 'react'

export default function BannerSection({bannerData}) {
    return <section className="saas_banner_area_two">
        <div className="section_intro">
            <div className="section_container">
                <div className="intro">
                    <div className=" intro_content">
                        <h1 className="f_700 f_size_50 w_color f_p">{bannerData.title}</h1>
                        <p className="w_color f_size_18">{bannerData.subTitle}</p>
                        <div className="input-group subcribes">
                            <a className="typeform-share btn btn_submit f_size_15 f_500"
                               style={{left: 'unset !important', right: 'unset !important'}}
                               href="https://propertime.typeform.com/to/ELjnm3" data-mode="popup"
                               data-submit-close-delay="5" target="_blank" rel="noreferrer">{bannerData.buttonText} </a>
                            <script dangerouslySetInnerHTML={{
                                __html: `
                            (function() {var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q)}})()
                            `
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="shap_img" src={bannerData.backgroundImage.image.file.url}
             alt={bannerData.backgroundImage.image.name}/>
        <div className="animation_img wow fadeInUp" data-wow-delay="0.3s">
            <img src={bannerData.image.image.file.url} alt={bannerData.image.name}/>
        </div>
    </section>;
}
