import React from 'react'

export default function FooterSection({footerData, pageData, homeData, isHome}) {

    return <footer className="footer_area footer_dark_one footer_dark_ten pt_120">
        <div className="footer_top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="f_widget dark_widget company_widget wow fadeInUp" data-wow-delay="0.2s">
                            <a href="/" className="f-logo"><img src={footerData.logo.image.file.url}
                                                                srcSet={`${footerData.logo2x.image.file.url} 2x`}
                                                                alt={footerData.logo.name}/></a>
                            <div className="widget-wrap">
                                {footerData.email &&
                                <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>{footerData.emailLabel}</span>
                                    <a
                                        href={`mailto:${footerData.email}`} className="f_300">{footerData.email}</a>
                                </p>}
                                {footerData.phoneNumber && <p className="f_400 f_p f_size_15 mb-0 l_height34">
                                    <span>{footerData.phoneNumberLabel}</span> <a
                                    href={`tel:${footerData.phoneNumber}`}
                                    className="f_300">{footerData.phoneNumber}</a></p>}
                                {footerData.childContentfulFooterSectionAddressTextNode.address &&
                                <p className="f_400 f_p f_size_15 mb-0 l_height34" dangerouslySetInnerHTML={{__html: `<span>${footerData.addressLabel}</span> ${footerData.childContentfulFooterSectionAddressTextNode.address}`}}/>}
                            </div>
                            <div className="f_social_icon_two">
                                {footerData.facebook && <a href={footerData.facebook} className="ti-facebook">&nbsp;</a>}
                                {footerData.twitter && <a href={footerData.twitter} className="ti-twitter-alt">&nbsp;</a>}
                                {footerData.youtube && <a href={footerData.youtube} className="ti-youtube-alt">&nbsp;</a>}
                            </div>
                        </div>
                    </div>
                    {footerData.menus && footerData.menus.map((menu, idx) => <div key={idx}
                                                                                  className="col-lg-3 col-md-6">
                        <div
                            className={`f_widget dark_widget about-widget ${['pl_70', 'pl_20', 'pl_20', 'pl_20'][idx % 4]} wow fadeInUp`}
                            data-wow-delay={['0.4s', '0.6s', '0.8s', '0.8s'][idx % 4]}>
                            <h3 className="f-title f_600 t_color f_size_18 mb_40">{menu.title}</h3>
                            <ul className="list-unstyled f_list">
                                {menu.includeHomeSections && homeData.contentModules.map((section, jdx) => (section.linkText &&
                                    <li key={jdx}>
                                        <a href={`${isHome ? '' : '/'}#${section.anchor}`}>{section.linkText}</a>
                                    </li>)
                                )}
                                {menu.includePageSections && pageData.contentModules.map((section, jdx) => (section.linkText &&
                                    <li key={jdx}>
                                        <a href={`#${section.anchor}`}>{section.linkText}</a>
                                    </li>)
                                )}
                                {menu.items.map((item, jdx) => <li key={jdx}>
                                    <a href={item.url}>{item.text}</a>
                                </li>)}
                            </ul>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
        <div className="footer_bottom dark_f_bottom">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-5 col-sm-12">
                        <p className="mb-0 f_300">© {(new Date()).getFullYear()} {footerData.copyright}</p>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12">
                        <ul className="list-unstyled f_menu text-right">
                            {footerData.bottomMenu.items.map((item, jdx) => <li key={jdx}>
                                <a href={item.url}>{item.text}</a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
        ;
}
