import React from 'react'
import Helmet from 'react-helmet';
import SEOHeader from './seo-header';
import AnalyticsHeader from './analytics-header';

export default function PageHead(props) {
    return <>
        <Helmet>
            <title>{props.pageData.title}</title>
        </Helmet>
        <SEOHeader {...props} />
        <AnalyticsHeader {...props}/>
    </>;
}
