import React from 'react'
import Helmet from 'react-helmet';

export default function SubscribeSection({subscribeData}) {
    return <section className="saas_subscribe_area">
        <div className="container">
            <div className="row saas_action_content wow fadeInUp" data-wow-delay="0.2s">
                <div className="col-lg-8">
                    <h4 className="f_p f_size_30 l_height50 f_400 t_color mb-0">{subscribeData.message}</h4>
                </div>
                <div className="col-lg-4 d-flex justify-content-end">
                    <a className="typeform-share button f_size_15 f_500"
                       href="https://propertime.typeform.com/to/ELjnm3" data-mode="popup"
                       style={{
                           display: 'inline-block',
                           textDecoration: 'none',
                           backgroundColor: '#267DDD',
                           color: 'white',
                           cursor: 'pointer',
                           fontFamily: 'Helvetica,Arial,sans-serif',
                           fontSize: 20,
                           lineHeight: '50px',
                           textAlign: 'center',
                           margin: 0,
                           height: 50,
                           padding: '0px 33px',
                           borderRadius: 4,
                           maxWidth: '100%',
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           fontWeight: 'bold',
                           fontSmooth: 'auto',
                       }}
                       data-submit-close-delay="5" target="_blank" rel="noreferrer">{subscribeData.button.text} </a>
                    <Helmet>
                    <script dangerouslySetInnerHTML={{
                        __html: `
                    (function() {var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q)}})()
                    `
                    }}/>
                    </Helmet>
                </div>
            </div>
        </div>
    </section>
        ;
}
