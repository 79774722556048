import React from 'react'
import moment from 'moment';

export default function BlogContentSection({postData}) {
    const currYear = moment().format('YY');
    const postYear = moment(postData.publishedOn).format('YY');
    const postMonth = moment(postData.publishedOn).format('MMM');
    const postDay = moment(postData.publishedOn).format('DD');

    return <section className="blog_area sec_pad">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 blog_sidebar_left">
                    <div className="blog_single mb_50">
                        <img className="img-fluid feature-image" src={postData.featuredImage.file.url}
                             alt={postData.featuredImage.title}/>
                        <div className="blog_content">
                            <div className="post_date">
                                <h2>{postDay}
                                    <span>{postMonth} {`${currYear === postYear ? '  ' : ' ' + postYear + ' '}`}</span>
                                </h2>
                            </div>
                            <a href={`/kb/${postData.slug}`}><h5
                                className="f_p f_size_20 f_500 t_color mb-30">{postData.title}</h5></a>
                            <div className="f_300 mb-30"
                                 dangerouslySetInnerHTML={{__html: postData.childContentfulPostPostContentTextNode.childMarkdownRemark.html}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        ;
}
