import React from 'react'

export default function ContactUsForm() {
    return <div className="contact_form">
        <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mt_100 mb_40">שלח לנו הודעה</h2>
        <form className="contact_form_box" method="post" id="contactForm" name="contactForm" action="/thank-you-for-contacting-us/"
              data-netlify="true">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group text_box">
                        <input type="text" id="firstName" name="firstName" placeholder="שם פרטי" required />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group text_box">
                        <input type="text" id="lastName" name="lastName" placeholder="שם משפחה" required />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group text_box">
                        <input type="text" name="email" id="email" style={{direction: 'ltr', textAlign: 'right'}}
                               placeholder="כתובת מייל" required />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group text_box">
                        <textarea name="message" id="message" cols="30" rows="10" placeholder="איך נוכל לעזור לך?"
                                  required></textarea>
                    </div>
                </div>
            </div>
            <input type="hidden" name="form-name" value="contactForm" />
            <button type="submit" className="btn_three">שלח הודעה</button>
        </form>
    </div>
        ;
}
