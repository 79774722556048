import React from 'react'

export default function TestimonialSection({testimonialsData}) {
    return <>
        <a id="testimonials" href='#testimonials' style={{visibility: 'hidden'}}>_</a>
        <section className="agency_testimonial_area bg_color sec_pad">
            <div className="container">
                <h2 className="f_size_30 f_600 t_color3 l_height40 text-center mb_60 wow fadeInUp"
                    data-wow-delay="0.1s">{testimonialsData.title}</h2>
                <div className="agency_testimonial_info">
                    <div className="testimonial_slider owl-carousel">
                        {testimonialsData.testimonials.map((testimonial, idx) => <div key={idx}
                            className={`testimonial_item text-center ${['left','center','right','','','','',''][idx]}`}>
                            <div className="author_img"><img className="testimonial_avatar"
                                                             src={testimonial.image.file.url}
                                                             alt={testimonial.image.name} /></div>
                            <div className="author_description">
                                <h4 className="f_500 t_color3 f_size_18">{testimonial.name}</h4>
                                <h6>{testimonial.childContentfulTestimonialTitleTextNode.title} @ {testimonial.companyName}</h6>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: testimonial.childContentfulTestimonialQuoteTextNode.childMarkdownRemark.html}}/>
                        </div>)}
                    </div>
                </div>
            </div>
        </section>
        </>;
}
